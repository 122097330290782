define("ember-get-config/index", ["exports", "@embroider/macros/runtime"], function (_exports, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global require */

  let configModulePath = `${(0, _runtime.config)("/Users/cityowl/Code/wildfire-dev/projects/tribe.zuleikha-chaudhari.com/applications/junction/node_modules/ember-get-config").modulePrefix}/config/environment`;
  var _default = _exports.default = require(configModulePath).default;
});